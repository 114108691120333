@import '../../../vars';

.container-messages{
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  z-index: 99;
  top: -10vh;
}

.container-messages.display{
  animation-name: animationDisplayMessage;
  animation-duration: 10s;
  animation-timing-function: ease;
}

@keyframes animationDisplayMessage {
  0% {top: -10vh;}
  10% {top: 5vh;}
  50% {top: 5vh;}
  75% {top: 5vh;}
  95% {top: 5vh;}
  100% {top: -10vh;}
}

.message{
  width: 85vh;
  font-size: 1.8vh;
  padding-top: 1.6vh;
  padding-bottom: 1.6vh;
  display: flex;
  align-items: center;
  border-radius: 2vw;
  padding-right: 2vh;
  box-sizing: border-box;
}
.message img{
  height: 2vh;
  margin-left: 2vh;
  margin-right: 1.2vh;
}

.message.success{
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #12BEAD;
  color: $success-color-600 ;
}
.message.error{
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), $error-color;
  color: $error-color;
}