@import '../../../vars';

#colonneFormSmallNoShadow{
  position: relative;
  width: 53.61vh;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  color: $text-color;
  padding: 6.34vh;
  @include m1025() {
    width: 100vw;
    min-height: 70vh;
    overflow: auto;
    box-sizing: border-box;
  }
}

.text {
  position: relative;
}

.slideLeft {
  animation: slideLeft 1s ease-in-out;
}
.slideRight {
  animation: slideRight 1s ease-in-out;
}

.fadeInButton {
  animation : 1500ms ease 0ms 1 normal none running fadeInAnimation;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fadeOutButton {
  opacity: 0;
  animation : fadeOutAnimation .5s ease-in-out ;
}

@keyframes fadeInAnimation {
  0% { opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeOutAnimation {
  0% { opacity: 1;}
  100% {opacity: 0;}
}

@keyframes slideLeft {
  0% {left: 0}
  100% {left:-1920px; display: none}
}
@keyframes slideRight {
  0% {right: -1920px}
  100% {right: 0}
}

#colonneFormSmallNoShadow h1{
  font-size: 3.2vh!important;
  line-height: 5vh;
  margin-bottom: 3vh;
  margin-top: 7vh;
  font-weight: 600;
  @include m1025() {
    margin-top: 0;
  }
}

#colonneFormSmallNoShadow>div>h2{
  @include m1025() {
    display:none;
  }
}

#colonneFormSmallNoShadow p{
  font-size: 1.6vh;
  line-height: 2.8vh;
  margin-bottom: 3.5vh;
}

#colonneImgBigOnBoarding{
  flex: 1;
  padding: 3.34vh;
  box-sizing: border-box;
  @include m1025() {
    height: 15vh;
    padding: 0;
  }
}
#colonneImgBigOnBoarding>div{
  background-image: url("../../../assets/img/onboarding.jpg");
  height: 100%;
  background-size: cover;
  border-radius: 2vw;
  position: relative;
  @include m1025() {
    border-radius: 0;
  }
}


#colonneImgBigOnBoarding1{
  flex: 1;
  padding: 3.34vh;
  box-sizing: border-box;
  @include m1025() {
    height: 15vh;
    padding: 0;
  }
}
#colonneImgBigOnBoarding1>div{
  background-image: url("../../../assets/img/onboarding1.jpg");
  height: 100%;
  background-size: cover;
  border-radius: 2vw;
  position: relative;
  @include m1025() {
    border-radius: 0;
  }
}

#colonneImgBigOnBoarding2{
  flex: 1;
  padding: 3.34vh;
  box-sizing: border-box;
  @include m1025() {
    height: 15vh;
    padding: 0;
  }
}
#colonneImgBigOnBoarding2>div{
  background-image: url("../../../assets/img/onboarding2.jpg");
  height: 100%;
  background-size: cover;
  border-radius: 2vw;
  position: relative;
  @include m1025() {
    border-radius: 0;
  }
}


.link {
  text-decoration: none;
  font-weight: 500;
  font-size: 1.6vh;
}

.text-orange{
  color: $color-primary
}

#contact-us{
  font-size: 1.6vh;
  margin-top: 2vh;
}

#contact-us li{
  list-style-type: none;
  margin-bottom: 2vh;
}

#contact-us li.container-contact{
  display: flex;
  align-items: center;
}

#contact-us li.container-contact a{
  display: inline-block;
  background-color: $gray-color-200;
  border-radius: 2vw;
  margin-right: 1.5vh;
  padding: 1.3vh;
  text-decoration: none;
  display: flex;
  align-items: center;
}

#contact-us li.container-contact a img{
  height: 2vh;
  margin-right: 1.1vh;
}

#contact-us li.container-contact a:first-child{
  background-color: #ED6986;
  color: $color-white;
}

#contact-us li.container-contact a:last-child{
  background-color: $color-white;
  border: 1px solid $color-white-alt;
  box-sizing: border-box;
  color: #29273F;
}

.navigation-bottom{
  position: absolute;
  bottom: 3vh;
  left: 5vh;
  width: 83%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6vh;
}

.navigation-bottom .container-button-next{
  width: 15vh;
}

.navigation-bottom .container-button-next button{
  font-size: 1.6vh!important;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  padding: 1.3vh;
}

.navigation-bottom .container-button-next button{
  font-size: 1.6vh!important;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  padding: 1.3vh;
}

.navigation-bottom .container-button-next button img svg{
  margin-left: 2vh;
  height: 2vh;
}

#colonneFormSmallNoShadow h2{
  font-size: 2.5vh!important;
  line-height: 5vh;
  margin-bottom: -6.5vh;
  margin-top: 7vh;
  font-weight: 600;
}

.navigation-bottom .container-button-next button svg{
  margin-left: 2vh;
  height: 2vh;
  fill: $color-white;
}





