@import '../../../vars';

#colonneImg{
  width: 53.61vh;
  box-sizing: border-box;
  color: $color-white;
  padding: 3.34vh;
  @include m1025() {
    display: none;
  }
}
#colonneImg>div{
  background-color: $color-background-rose;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  height: 100%;
  border-radius: 2vw;
  padding: 3.34vh;
  box-sizing: border-box;
  color: $text-color;
  font-family: Poppins
}
.colonneTrois{
  width: 1px;
}
#colonneImg h1, #colonneImg h2{
  margin-bottom: 1vh;
}
#colonneImg h1{
  font-size: 4vh;
  line-height: 6vh;
  font-weight: 800;
}
#colonneImg h2{
  font-size: 2.5vh;
  line-height: 3.8vh;
  font-weight: 400;
}
#colonneImg img {

}