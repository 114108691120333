@import '../../../vars';

.button{
  width: 100%;
  text-align: center;
  font-size: 1.8vh;
  padding-top: 1.6vh;
  padding-bottom: 1.6vh;
  border: none;
  border-radius: 2vw;
  margin-top: 1.9vh;
  background-color: transparent;
  transition: 0.2s;
  box-sizing: border-box;
  fill: currentColor;
}

.button img{

}

.orange{
  background-color: $color-primary;
  box-sizing: border-box;
  color: $color-white;
  cursor: pointer;
  transition: 0.2s;
  border: 1px solid $color-primary;
  fill: currentColor;
}

.white{
  background-color: $color-white;
  border: 1px solid $color-white-alt;
  box-sizing: border-box;
  color: #29273F;
  cursor: pointer;
  transition: 0.2s;
  fill: currentColor;
}
.orange:hover svg path,

.orange:hover{
  background-color: $color-white;
  border: 1px solid $color-white-alt;
  box-sizing: border-box;
  color: #29273F;
  cursor: pointer;
  fill: $color-primary;
}

.white:hover{
  background-color: $color-primary;
  border: 1px solid $color-primary;
  box-sizing: border-box;
  color: $color-white;
  cursor: pointer;
  fill: currentColor;
}


.button:disabled{
  background: $color-background-rose;
  opacity: 0.5;
  color: $color-primary;
  cursor: default;
  border: 1px solid rgba(255, 255, 255, 0.75);
  fill: currentColor;
}

.button:disabled:hover{
  background: $color-primary-ligth;
  color: $color-primary;
  cursor: default;
  border: 1px solid rgba(255, 255, 255, 0.75);
  fill: currentColor;
}