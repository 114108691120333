@import '../../../vars';

#colonneFormSmall {
  position: relative;
  width: 53.61vh;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  color: $text-color;
  background-color: $color-white;
  padding: 6.34vh;
  @include m1025() {
    width: 100vw;
    min-height: 66vh;
    overflow: auto;
    box-sizing: border-box;
  }
}

#colonneFormSmall h1 {
  font-size: 3.2vh !important;
  line-height: 5vh;
  margin-bottom: 3vh;
  margin-top: 7vh;
  font-weight: 600;
  @include m1025() {
    margin-top: 0;
  }
}

#colonneFormSmall p {
  font-size: 1.6vh;
  line-height: 2.8vh;
  margin-bottom: 3.5vh;
}

#colonneImgBig {
  flex: 1;
  padding: 3.34vh;
  box-sizing: border-box;
  @include m1025() {
    max-height: 15vh;
    padding: 0;
  }
}

#colonneImgBig > div {
  height: 100%;
  background-size: cover;
  border-radius: 2vw;
  background-image: url("../../../assets/img/MicrosoftTeams-image.png");
  @include m1025() {
    height: 100%;
    background-size: cover;
    border-radius: 2vw;
    height: 20vh;
  }
}

.link {
  text-decoration: none;
  font-weight: 500;
  font-size: 1.6vh;
}

.text-pink {
  color: $color-primary;
}

#contact-us {
  font-size: 1.6vh;
  margin-top: 2vh;
  animation: fadeIn linear 1s;
  -webkit-animation: fadeIn linear 1s;
  -moz-animation: fadeIn linear 1s;
  -o-animation: fadeIn linear 1s;
  -ms-animation: fadeIn linear 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#contact-us li {
  list-style-type: none;
  margin-bottom: 2vh;
}

#contact-us li.container-contact {
  display: flex;
  align-items: center;
}

#contact-us li.container-contact a {
  display: inline-block;
  background-color: #E1E1E1;
  border-radius: 2vw;
  margin-right: 1.5vh;
  padding: 1.3vh;
  text-decoration: none;
  display: flex;
  align-items: center;
}

#contact-us li.container-contact a img {
  height: 2vh;
  margin-right: 1.1vh;
}

#contact-us li.container-contact a:first-child {
  background-color: $color-primary;
  color: $color-white;
}

#contact-us li.container-contact a:last-child {
  background-color: $color-white;
  border: 1px solid $text-color-300;
  box-sizing: border-box;
  color: $text-color;
}

.navigation-bottom {
  position: absolute;
  bottom: 3vh;
  left: 5vh;;
  width: 83%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6vh;
}

.navigation-bottom .container-button-next {
  width: 17vh;
}

.navigation-bottom .container-button-next button {
  font-size: 1.6vh !important;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  padding: 1.3vh;
}

.navigation-bottom .container-button-next button img {
  margin-left: 2vh;
  height: 2vh;
}

// Modal

.modalBackground {
  background-color: $text-color;
  opacity: 0.2;
  z-index: 100;
  position: absolute;
  left: 0vh;
  top: 0vh;
  width: 100vw;
  height: 100vh;
}

.content {
  background-color: white;
  width: 68.35vh;
  @include m1025() {
    width: 90vw;
    box-sizing: border-box;
  }
  //height: 32.67vh;
  border-radius: 2%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
}

.content h2 {
  color: $text-color;
  margin: 5vh;
  font-size: 2vh;
}

.content p {
  color: $text-color;
  margin: 2vh 5vh 0vh 5vh;
  font-size: 1.5vh;
}

.containerButton {
  display: flex;
  margin: 5vh 5vh 5vh 5vh;
  justify-content: space-between;
}

.containerButton .button:last-child {
  margin-left: 2vh;
}

.button {
  margin-top: 0 !important;
}