@use 'sass:color';

@mixin m1025($size: 1025px) {
  @media (max-width: $size) {
    @content
  }
}
:root {
  --primary-color: #FF6744;
  --background-color: #FFDED4;
  --text-color: #4F0C28;
}
$color-primary: var(--primary-color, #FF6744);
$color-background-rose: var(--background-color, #FFDED4);
$color-secondary: rgba(255, 103, 68, .2);
$text-primary: #4F0C28;
$text-secondary: rgba(79, 12, 40, .50);
$color-background-light-grey: #EBEDEF;
$color-background-beige: #FFFFF2;
$color-background-lightPurple: #C0BFEB;

$color-white: #FFFFFF;
$color-primary-ligth: mix(#FF6744, $color-white, 5%);
$color-primary-dark: mix(#FF6744, $color-white, 25%);

$color-secondary-light: mix($text-primary, $color-white, 10%);

$color-white-alt: #DDE2E9;

$background-color-beige : #FFFFF2;
$background-color-rose : #FFDED4;

$primary-color : #FF6744;
$primary-color-100: #FEF7F9;
$primary-color-200: #FDF0F3;
$primary-color-300: #FDF0F3;
$primary-color-400: #FBD9E1;
$primary-color-600: #B24F64;
$primary-color-700: #773443;
$primary-color-800: #592418;

$secondary-color: #C0BFEB;
$secondary-color-100: #FCFCFE;
$secondary-color-200: #F9F9FD;
$secondary-color-300: #EFEFFA;
$secondary-color-400: #DFDFF5;
$secondary-color-600: #908FB0;
$secondary-color-700: #606076;
$secondary-color-800: #434352;

$text-color: var(--text-color, #4F0C28);
$text-color-100: #F6F3F4;
$text-color-200: #EDE7E9;
$text-color-300: #D3C2C9;
$text-color-400: #A78593;
$text-color-600: #3B091E;
$text-color-700: #280614;
$text-color-800: #1C040E;

$success-color: #33D9B2;
$success-color-100: #F5FDFB;
$success-color-200: #EBFBF7;
$success-color-300: #CCF6EC;
$success-color-400: #99ECD8;
$success-color-600: #26A385;
$success-color-700: #1A6D59;
$success-color-800: #124C3E;

$warning-color: #F3AA1C;
$warning-color-100: #FEFBF4;
$warning-color-200: #FEF6E8;
$warning-color-300: #FCEAC6;
$warning-color-400: #F9D58D;
$warning-color-600: #B68015;
$warning-color-700: #79550E;
$warning-color-800: #05141A;

$error-color: #DC392B;
$error-color-100: #DC392B;
$error-color-200: #FBEBEA;
$error-color-300: #F6CDCA;
$error-color-400: #EE9C95;
$error-color-600: #A52B20;
$error-color-700: #6E1C16;
$error-color-800: #4D140F;

$gray-color-100: #F9FAFA;
$gray-color-200: #F0F2F3;
$gray-color-300: #E0E4E8;
$gray-color-400: #92A8BD;
$gray-color-500: #58697C;
$gray-color-600: #2D3D4E;
$gray-color-black: #171D25;

svg, path, svg path {
  fill: $color-primary;
}
img svg path {
  fill: $color-primary!important;
}