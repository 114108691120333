@import '../../../vars';

.input-smooth{
  width: 100%;
  position: relative;
}

.input-smooth input{
  width: 100%;
  box-sizing: border-box;
  background: $color-white;
  border: 1px solid $text-color-300;
  box-sizing: border-box;
  border-radius: 2vw;
  padding-top: 1.5vh;
  padding-bottom: 1.7vh;
  padding-left: 4.7vh;
  padding-right: 1.2vh;
  font-size: 1.8vh;
  margin-bottom: 2.9vh;
  color: $text-primary;
  &.grey{
    background: linear-gradient(0deg, $text-color-200,$text-color-200 ), $text-color;
  }
}

.input-smooth img{
  position: absolute;
  left: 1.85vh;
  top: 2vh;
  width: 1.8vh;
}

.input-smooth .eye{
  position: absolute;
  left: 41vh;
  top: 1.55vh;
  width: 2.5vh;
}

.input-smooth svg {
  position: absolute;
  top: 1.5vh;
  right: 1.8vh;
  fill: #545365;
}

.input-smooth svg:hover {
  transform: scale(1.1);
}

.input-smooth label{
  position: absolute;
  top: 1.6vh;
  left: 4.8vh;
  font-size: 1.8vh;
  color: $text-secondary;
  transition: 0.2s;
}
.focus-within label{
    top: 0.8vh;
    font-size: 1.3vh;
    color: $text-secondary;
  }
.focus-within input{
    padding-top: 2.3vh;
    padding-bottom: 0.9vh;
    box-shadow: 0 0 1.5px 1.5px $text-color-300;
    outline: none;
  }