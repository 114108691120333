@import "../../../vars";

#colonneForm{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

#colonneForm #logo{
  width: 21.67vh;
  margin-bottom: 3.9vh;
}

#colonneForm h1{
  font-weight: 600;
  font-size: 2.5vh;
  margin-bottom: 1.9vh;
  color: $text-color;
  text-align: center;
  @include m1025() {
    padding: 2vh;
    box-sizing: border-box;
  }
}

#colonneForm h2{
  font-weight: normal;
  color: $text-color;
  opacity: 0.5;
  font-size: 1.7vh;
  margin-bottom: 4.8vh;
  text-align: center;
  @include m1025() {
    padding: 2vh;
    box-sizing: border-box;
  }
}
#colonneForm a{
  font-weight: normal;
  color: $color-primary;
  opacity: 1;
  font-size: 1.7vh;
  margin-bottom: 4.8vh;
}

.form-welcome{
  width: 44.92vh;
  display: flex;
  flex-flow: column;
}

.passwordItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: 45vh;
  height: 11vh;

  /* Inside Auto Layout */
  flex-grow: 0;
  margin: 0vh 0vh 1.8vh 0vh;
}

.itemLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.3vh;
  & p {
    width: 20vh;
    font-size: 1.8vh;
    margin-left: 1.3vh;
    color: $text-color;
  }
  & img {
    width: 2.7vh;
    height: 2.7vh;
  }
}

.rememberMe {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  & p {
    margin-left: 1.3vh;
    font-size: 1.8vh;
  }
}

.stay-connected-container{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1vh;
  font-size: 1.7vh;
  color: $text-color;
  cursor: pointer;
}
.stay-connected-container .stay-connected{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: $color-white;
  border: 0.2vh solid #D3D8E0;
  width: 2vh;
  height: 2vh;
  margin-right: 1vh;
  cursor: pointer;
  box-sizing: border-box;
}
.stay-connected-container .stay-connected.active{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: $color-white;
  border: 0.5vh solid $color-primary;
  width: 2vh;
  height: 2vh;
  margin-right: 1vh;
  cursor: pointer;
  box-sizing: border-box;
}

.password-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5vh;
}

.password-container a {
  color: $color-primary;
  font-size: 1.7vh;
}
#accountBlocked {
  background-color: $background-color-rose;
  color: $text-color;
  border-radius: 20px;
  padding: 3vh 2vw;
  opacity: initial!important;
}


