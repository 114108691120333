@import '../../../vars';

#wrapper{
  display: flex;
  flex-flow: row;
  min-height: 100vh;
  width: 100vw;
  transition: 0.5s;
  @include m1025() {
    display: flex;
    min-height: 100vh;
    width: 100vw;
    overflow: auto;
    justify-content: flex-end;
  }
}
.lightPurple {
  background: $color-background-lightPurple;
}
.beige{
  background: $color-background-beige;
}
.rose{
  background: $color-background-rose;
}

.fade {
  animation : 1500ms ease 0ms 1 normal none running fadeInAnimation;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.colonneFormSlide {
  animation: slide 1s ease-in;
}

@keyframes slide {
  0% {left: 0}
  100% {left:-1500px;}
}

.fadeOut {
  animation : 1500ms ease 0ms 1 normal none running fadeOutAnimation;
}

@keyframes fadeInAnimation {
  0% { opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeOutAnimation {
  0% { opacity: 1;}
  100% {opacity: 0;}
}

