@import '../../../vars';
.centered{
  text-align: center;
}

.title-cgu{
  font-size: 3.2vh!important;
  line-height: 5vh;
}

.container-cgu{
  position: relative;
  width: 58.59vh;
  height: 60vh;
  overflow: hidden;
  color: $text-primary;
  margin-bottom: 3vh;

  @include m1025() {
    width: 100vw;
    padding: 2vh;
    box-sizing: border-box;
    height: 35vh;
  }
}

.bloc-cgu{
  height: 60vh;
  overflow: auto;
  font-size: 1.6vh;
  color: $text-primary;
  @include m1025() {
    height: 35vh;
  }
}

.button-bottom{
  width: 58.59vh;
  @include m1025() {
    width: 90vw;
  }
}

.container-cgu>.bottom-gradient{
  position: absolute;
  bottom: -5vh;
  left: 0;
  width: 100%;
  height: 20vh;
  z-index: 50;
  background: $color-white;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 60%);
}

.subtitle-cgu{
  font-size: 2vh;
  font-weight: 500;
}

.biggerText {
  font-weight: 600;
  margin-bottom: 2.5vw;
  text-transform: uppercase;
  text-align: center;
}

.intermediateText {
  font-weight: 600;
  margin-bottom: 2.5vw;
  text-transform: uppercase;
  text-align: center;
}