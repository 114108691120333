@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import "vars.scss";
body{
  overflow: hidden;
  width: 100%;
  font-family: Inter;
  @include m1025() {
    overflow: auto;
  }
}

*{
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
}

.fade {
  animation : 1500ms ease 0ms 1 normal none running fadeInAnimation;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fadeOut {
  opacity: 0;
  animation : 1500ms ease 0ms 1 normal none running fadeOutAnimation;
}
input[type="text"], input[type="password"]{
  @include m1025() {
    font-size: 16px;
  }
}

@keyframes fadeInAnimation {
  0% { opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeOutAnimation {
  0% { opacity: 1;}
  100% {opacity: 0;}
}
